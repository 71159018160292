
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return { key: 0 };
  },
  methods: {
    reset() {
      this.key++;
    },
  },
});
